<template>
  <div class="admin-wrapper">
    <admin-auth v-if="$store.state.user == null"/>
    <div v-else>
      <div class="admin-wrapper-content">
        <admin-sidebar />
        <div class="f-r" style="width: 100%">
          <div class="admin-sidebar-padding"></div>
          <router-view style="flex: 1; margin-top: 80px"/>
        </div>
      </div>
      <admin-navbar />
    </div>
  </div>
</template>

<script>
import AdminNavbar from '../../components/admin/AdminNavbar.vue'
import AdminSidebar from '../../components/admin/AdminSidebar.vue'
import AdminAuth from './AdminAuth.vue'
export default {
  components: { AdminAuth, AdminNavbar, AdminSidebar },
}
</script>

<style scoped>

.admin-wrapper-content {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.admin-sidebar-padding {
  max-width: var(--l-sidebar-max-width);
  width: var(--l-sidebar-width);
  box-sizing: border-box;
  padding: 80px 20px;
}

</style>