<template>
  <div class="admin-side shadow">
    <div class="admin-side-items">
      <div>
        <h3 class="text--dark" style="margin: 10px 20px">Dashboard</h3>
        <router-link to="/admin/"><p class="admin-side-item text--dark" :class="{'admin-side-item-selected': selected==0}">Start</p></router-link>
        <router-link to="/admin/posts"><p class="admin-side-item text--dark" :class="{'admin-side-item-selected': selected==1}">Inlägg</p></router-link>
        <router-link to="/admin/background"><p class="admin-side-item text--dark" :class="{'admin-side-item-selected': selected==2}">Bakgrundsbild</p></router-link>
      </div>
      <div class="divider" style="margin: 20px 10px"></div>
      <div>
        <router-link to="/"><p class="admin-side-item text--dark">Till hemsidan</p></router-link>
        <p class="admin-side-item text--dark" @click="$store.dispatch('signOut')">Logga ut</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // selected: 0,
    }
  },
  computed: {
    selected() {
      if(this.$route.path.includes('/admin/posts')) return 1
      else if(this.$route.path.includes('/admin/background')) return 2
      else return 0
      // return this.$route.matched.some(route => route.path.includes('/admin'))
    }
  },
}
</script>

<style scoped>

.admin-side {
  z-index: 999;
  position: fixed;
  background-color: var(--c-text-light);
  padding: 80px 0;
  max-width: var(--l-sidebar-max-width);
  width: var(--l-sidebar-width);
  height: 100%;
  box-sizing: border-box;
}

.admin-side-item {
  width: 100%; 
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
  border-right: 5px solid transparent;

  transition: background-color 200ms ease, border-right 200ms ease;
}
.admin-side-item:hover {
  background-color: var(--c-disabled);
}
.admin-side-item.admin-side-item-selected {
  border-right: 5px solid var(--c-primary);
}


</style>