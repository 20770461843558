<template>
  <div class="nav">
    <div class="f-r f-ac">
      <navbar-logo />
      <h3 style="margin: 0 20px">Admin</h3>
    </div>
    
    <!-- <div class="nav-buttons">
      <div class="nav-links">
        <router-link to="/"><p class="text-button">Tillbaka till hemsidan</p></router-link>
        <p class="text-button" @click="$store.dispatch('signOut')">Logga ut</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import NavbarLogo from '../svgs/NavbarLogo.vue'

export default {
  components: {
    NavbarLogo
  },
}
</script>

<style scoped>

.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 65px;
  width: 100vw;
  box-sizing: border-box;

  background-color: var(--c-primary);
  
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.nav-links {
  display: flex;
  flex-direction: row;
}

p {
  margin: 0 20px;  
}

</style>