<template>
  <div class="ad-auth">
    <div class="ad-si-form-container shadow">
      <navbar-logo class="ad-si-form-logo" />
      <h1 class="text--dark" style="margin: 10px 0">Admin</h1>
      <p class="text--dark">
        Denna sida är endast till för administratörer av Blomstrande Liv. Om du inte är en administratör, vänligen återgå till 
        <router-link class="hoverable text--primary" to="/">hemsidan</router-link>.
      </p>
      <form @submit.prevent="handleSubmit">
        <label for="email" class="text--dark" style="margin-bottom: 4px">Epost</label>
        <input v-model="email" name="email" type="email" style="margin-bottom: 20px" />
        <label for="password" class="text--dark" style="margin-bottom: 4px">Lösenord</label>
        <input v-model="password" name="password" type="password" />
        <button class="button" style="margin-top: 20px;">Logga in</button>
      </form>
    </div>
    <devyne-logo />
  </div>
</template>

<script>
import DevyneLogo from '../../components/svgs/DevyneLogo.vue'
import NavbarLogo from '../../components/svgs/NavbarLogo.vue'
export default {
  components: { NavbarLogo, DevyneLogo },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    }
  },
  methods: {
    async handleSubmit() {
      if(!(this.email && this.password)) {
        await this.$store.dispatch('signOut')
      } else {
        try {
          this.loading = true;
          await this.$store.dispatch('signIn', { email: this.email, password: this.password })
          this.loading = false;
        } catch(e) {
          this.loading = false;
          this.error = e.message
        }
      }
    }
  }
}
</script>

<style scoped>

.ad-auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--c-disabled);
  width: 100vw;
  height: 100vh;
}

.ad-si-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  margin: 50px var(--horizontal-margin);
  padding: 30px 20px;


  background-color: var(--c-background);
}

.ad-si-form-container form {
  margin-top: 20px;
  align-items: center;
}


.ad-si-form-logo >>> * {
  fill: var(--c-primary);
}

</style>